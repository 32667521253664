import gql from "graphql-tag"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicCompSetFragment } from "./basicCompSetFragment"
import { basicCompScoreFragment } from "./basicCompScoreFragment"
import { basicGymAreaFragment } from "./basicGymAreaFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { mediumProblemFragment } from "../../guides/graphql/mediumProblemFragment"
import { mediumRouteFragment } from "../../guides/graphql/mediumRouteFragment"
import { feedCommentFragment } from "../../feed/graphql/feedCommentFragment"

export const basicCompFragment = gql`
  fragment basicCompFragment on Comp {
    _id
    slug
    name
    picture
    startDate
    endDate
    description
  }
`

export const compFragment = gql`
  fragment compFragment on Comp {
    _id
    type
    slug
    name
    isHidden
    isInviteOnly
    description
    rules
    registrationMessage
    badge
    picture
    isTeamComp
    isChargingTeam
    minNumberOnTeam
    maxNumberOnTeam
    isApplyingHandicap
    registrationInstructions
    startDate
    endDate
    beginRegistrationDate
    endRegistrationDate
    registrationURL
    isDroppingRound
    isAdditionalSendsBreakingTies
    isAllowingFloaters
    isRelativeScoringEnabled
    numClimbsBeforeBump
    isScoringRoutes
    isScoringProblems
    cost
    categoryCosts {
      _id
      category
      cost
    }
    categories
    isTeamCategory
    paymentType
    stripeConnectedAccountId
    isShowingOverallScores
    registrationCap
    hasRegistrationReachedCap
    earlyBirdAmountOff
    earlyBirdAmountOffDate
    gymMemberCost
    canBypassRegistration
    howToScoreYTID
    howToJudgeScoreYTID
    howToScoreYTLink
    isShowingHowToJudge
    isShowingHowToPeerScore
    isUsingScanners
    isAutoJoining
    rewardActivityId
    referralActivityId
    isHidingLeaderboard
    isHidingCompInMobileTab
    isHidingCompRules
    livestreamOembed {
      provider_name
      thumbnail_url
      author_name
      author_url
      url
      title
      html
    }
    timeSlots {
      _id
      name
      max
      isFull
      numFilled
    }
    discountedPrices {
      _id
      name
      price
      description
      type
      numberUsed
      numberAvailable
      isForMembers
      code
      expirationDate
      categories
    }
    gym {
      _id
      name
      slug
      canLinkToMRP
      isShowingScannerPoints
      gymAreas {
        _id
        name
        picture
        parentArea {
          _id
        }
      }
      isOverlappingGrades
      isHoldColorGrade
      isShowingGrades
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
    gyms {
      _id
      name
      slug
      isShowingGrades
      popMedia {
        ...basicMediaFragment
      }
      isShowingScannerPoints
      canLinkToMRP
      gymAreas {
        _id
        name
        picture
        parentArea {
          _id
        }
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      scannerActivities {
        _id
        type
        name
        minLogRate
        points
        image
        numScanners
      }
    }
    compSets {
      ...basicCompSetFragment
    }
    teams {
      _id
      slug
      name
      type
      picture
      isAllowingFloaters
      competitors {
        _id
        name
        user {
          ...basicUserFragment
        }
      }
    }
    notifications {
      _id
      title
      description
      image
      link
      expirationDate
      creator {
        _id
      }
    }
    competitorQuestions {
      _id
      title
      questionType
      isRequired
      isYesRequired
      options
    }
    judges {
      ...basicUserFragment
    }
    usersToNotifyOfBumps {
      ...basicUserFragment
    }
    leaderboardConfig {
      numColumns
      rowsFrozen
      categories
      setIds
      slideInterval
      leaderboardFontSize
    }
  }
  ${basicCompSetFragment}
  ${basicMediaFragment}
  ${basicUserFragment}
`

export const scoreFragment = gql`
  fragment scoreFragment on CompScore {
    _id
    problem {
      _id
      type
      name
      setterGrade
      gradeColor
      gradeName
      picture
      maxCompScores {
        compSet
        maxCompScore
      }
      color
      gym {
        _id
        isOverlappingGrades
        isShowingGrades
        problemGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
      }
    }
    route {
      _id
      type
      name
      setterGrade
      gradeColor
      gradeName
      picture
      maxCompScores {
        compSet
        maxCompScore
      }
      color
      gym {
        _id
        isOverlappingGrades
        isShowingGrades
        routeGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
      }
    }
    compSet {
      _id
      slug
      isRelativeScoring
      pointsPerGradeDiff
      relativeScore
      isGradingHolds
      judgeType
      isWorldCupBouldering
      isWorldCupBoulderingPoints
      isWorldCupSport
      isTimeLeft
      maxTimesClimbScored
      scoreBonuses {
        _id
        name
        description
        points
      }
    }
    send {
      _id
      type
      slug
    }
    score
    scoreBonuses {
      _id
      name
      description
      points
    }
    createdAt
    judgedAt
    isPlusGiven
    isTopControlled
    isZoneControlled
    attemptsToTop
    attemptsToZone
    controlledZone
    attemptsToZones
    problemIfWorldCup {
      _id
      name
    }
  }
`

export const competitorFragment = gql`
  fragment competitorFragment on Competitor {
    _id
    slug
    name
    category
    maxProblemGrade
    maxRouteGrade
    competitorAnswers {
      questionId
      answer
    }
    discountedPrice {
      _id
      name
      price
      description
      type
      numberUsed
      numberAvailable
      isForMembers
      code
      expirationDate
    }
    previousMaxRouteGrades {
      grade
      maxDate
    }
    previousMaxProblemGrades {
      grade
      maxDate
    }
    comp {
      _id
      slug
      name
      description
      picture
    }
    compSetIdsInvitedTo
    user {
      ...basicUserFragment
    }
    team {
      _id
      name
      slug
      picture
      admin {
        _id
      }
    }
    compScores {
      ...scoreFragment
    }
    timeSlot {
      _id
      name
    }
    competitorBonusActivities {
      compSet {
        _id
      }
      activity {
        _id
        name
        description
        points
      }
    }
    hasAskedCompNotificationPermissions
    isCheckedIn
    referredUser {
      ...basicUserFragment
    }
  }
  ${basicUserFragment}
  ${scoreFragment}
`

export const teamFragment = gql`
  fragment teamFragment on Team {
    _id
    slug
    name
    type
    picture
    category
    isAllowingFloaters
    admin {
      ...basicUserFragment
    }
    competitors {
      ...competitorFragment
      user {
        email
      }
    }
    pendingInvites {
      ...basicUserFragment
      email
    }
    emails
    timeSlot {
      _id
      name
    }
    comp {
      _id
      slug
      name
      picture
      endRegistrationDate
      isRelativeScoringEnabled
      isScoringProblems
      isScoringRoutes
      isTeamComp
      maxNumberOnTeam
      minNumberOnTeam
      gyms {
        _id
        isOverlappingGrades
        isShowingGrades
        problemGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
        routeGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
      }
    }
  }
  ${basicUserFragment}
  ${competitorFragment}
`

export const compScoresDownloadFragment = gql`
  fragment compScoresDownloadFragment on Comp {
    _id
    slug
    name
    isRelativeScoringEnabled
    isScoringProblems
    isScoringRoutes
    isTeamComp
    categories
    competitorQuestions {
      _id
      title
    }
    gyms {
      _id
      name
      slug
      isShowingGrades
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
    compSets {
      _id
      slug
      name
      isWorldCupSport
      isWorldCupBouldering
      isWorldCupBoulderingPoints
    }
    competitors {
      _id
      slug
      name
      category
      maxProblemGrade
      maxRouteGrade
      competitorAnswers {
        questionId
        answer
      }
      timeSlot {
        _id
        name
      }
      discountedPrice {
        _id
        name
      }
      team {
        _id
        name
        slug
        category
        timeSlot {
          _id
          name
        }
      }
      user {
        _id
      }
      compScores {
        _id
        score
        problem {
          _id
          slug
          name
        }
        route {
          _id
          slug
          name
        }
        compSet {
          _id
          slug
          name
          start
          isTimeLeft
        }
        activity {
          _id
          name
        }
      }
      competitorBonusActivities {
        compSet {
          _id
          slug
          name
          start
        }
        activity {
          _id
          name
          description
          points
        }
      }
    }
  }
`

export const compReportDownloadFragment = gql`
  fragment compReportDownloadFragment on Comp {
    _id
    slug
    name
    categories
    startDate
    endDate
    isTeamComp
    gyms {
      _id
      name
      isShowingGrades
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
      }
    }
    competitors {
      _id
      name
      category
    }
    teams {
      _id
      name
    }
    compScores {
      _id
      problem {
        _id
        type
        grade
      }
      route {
        _id
        type
        grade
      }
      competitor {
        _id
      }
    }
    problems {
      _id
      type
      name
      grade
      gym {
        _id
      }
    }
    routes {
      _id
      type
      name
      grade
      gym {
        _id
      }
    }
    financials {
      platform
      net
      gross
      payingCompetitors
    }
  }
`

export const compNotificationFragment = gql`
  fragment compNotificationFragment on CompNotification {
    _id
    type
    title
    description
    link
    image
    creator {
      ...basicUserFragment
    }
    isHidingMoreBtn
  }
  ${basicUserFragment}
`

export const GET_DOWNLOADABLE_COMP_SCORES = gql`
  query getDownloadableCompScores($slug: String!) {
    comp(slug: $slug) {
      ...compScoresDownloadFragment
    }
  }
  ${compScoresDownloadFragment}
`

export const GET_DOWNLOADABLE_COMP_REPORT = gql`
  query getDownloadableCompReport($slug: String!) {
    comp(slug: $slug) {
      ...compReportDownloadFragment
    }
  }
  ${compReportDownloadFragment}
`

export const GET_GYM_COMPS = gql`
  query GetGymComps($gymId: String!, $isIgnoringExpired: Boolean) {
    gymComps(gymId: $gymId, isIgnoringExpired: $isIgnoringExpired) {
      competitions {
        _id
        slug
        name
        description
        picture
        endDate
        isHidden
        beginRegistrationDate
        endRegistrationDate
      }
    }
  }
`

export const GET_COMP = gql`
  query GetComp($slug: String!) {
    comp(slug: $slug) {
      ...compFragment
    }
  }
  ${compFragment}
`

export const CREATE_COMP = gql`
  mutation CreateComp($comp: CompInput!) {
    createComp(comp: $comp) {
      ...compFragment
    }
  }
  ${compFragment}
`

export const EDIT_COMP = gql`
  mutation EditComp($comp: CompInput!) {
    editComp(comp: $comp) {
      ...compFragment
    }
  }
  ${compFragment}
`

export const DELETE_COMP = gql`
  mutation DeleteComp($compId: String!) {
    deleteComp(compId: $compId) {
      ...compFragment
    }
  }
  ${compFragment}
`

export const SAVE_COMP_LEADERBOARD_CONFIG = gql`
  mutation SaveCompLeaderboardConfig($config: CompLeaderboardConfigInput!) {
    saveCompLeaderboardConfig(config: $config) {
      ...compFragment
    }
  }
  ${compFragment}
`

export const CHECK_IN_COMPETITOR = gql`
  mutation CheckInCompetitor($competitorId: String!, $isCheckedIn: Boolean!) {
    checkInCompetitor(competitorId: $competitorId, isCheckedIn: $isCheckedIn) {
      ...competitorFragment
    }
  }
  ${competitorFragment}
`

export const GET_COMP_COMPETITORS = gql`
  query GetCompCompetitors($slug: String!) {
    comp(slug: $slug) {
      ...compFragment
      gym {
        _id
        slug
        name
        routeGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
        problemGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
      }
      teams {
        _id
        slug
        name
        type
        picture
        isAllowingFloaters
        emails
        userIds
        registrationDate
      }
      competitors {
        _id
        slug
        name
        category
        maxProblemGrade
        maxRouteGrade
        registrationDate
        isCheckedIn
        competitorAnswers {
          questionId
          answer
        }
        timeSlot {
          _id
          name
        }
        comp {
          _id
          slug
          name
          description
          picture
        }
        user {
          ...basicUserFragment
        }
        team {
          _id
          name
          category
          slug
          picture
          admin {
            _id
          }
        }
        discountedPrice {
          _id
          name
        }
      }
    }
  }
  ${compFragment}
  ${basicUserFragment}
`

export const GET_TEAM = gql`
  query GetTeam($slug: String!) {
    team(slug: $slug) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const GET_FULL_TEAM = gql`
  query GetFullTeam($slug: String!) {
    team(slug: $slug) {
      ...teamFragment
      comp {
        compSets {
          _id
          slug
          name
          start
          end
          isGradingHolds
          isRelativeScoring
          pointsPerGradeDiff
          relativeScore
          numClimbsBeforeBump
          judgeType
          competitorType
          isGradingAttempts
          attemptPoints
          maxTimesClimbScored
          numClimbsToScore
          numZoneHolds
          numClimbsToScorePerTeam
          categoriesAllowed
          isWorldCupSport
          isWorldCupBouldering
          isWorldCupBoulderingPoints
          isRelativeScoreMaxScore
          isWarningOfBump
          gymAreas {
            ...basicGymAreaFragment
          }
          bonusActivities {
            _id
            name
          }
          scoreBonuses {
            _id
            name
            description
            points
          }
        }
      }
    }
  }
  ${teamFragment}
  ${basicGymAreaFragment}
`

export const CHECK_FOR_TEAM = gql`
  query CheckForTeam($compSlug: String!) {
    checkForTeams(compSlug: $compSlug) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const GET_USER_COMPETITOR_FROM_COMP = gql`
  query GetUserCompetitorFromComp(
    $compId: String
    $userId: String
    $competitorCode: String
  ) {
    getUserCompetitorFromComp(
      compId: $compId
      userId: $userId
      competitorCode: $competitorCode
    ) {
      ...competitorFragment
      team {
        _id
        name
        slug
        picture
        timeSlot {
          _id
          name
        }
        admin {
          _id
        }
        comp {
          _id
        }
      }
      previousMaxRouteGrades {
        grade
        maxDate
      }
      previousMaxProblemGrades {
        grade
        maxDate
      }
    }
  }
  ${competitorFragment}
`

export const GET_USER_COMPETITORS_FROM_COMP = gql`
  query GetUserCompetitorsFromComp($compId: String, $userId: String) {
    getUserCompetitorsFromComp(compId: $compId, userId: $userId) {
      ...competitorFragment
      team {
        _id
        name
        slug
        picture
        timeSlot {
          _id
          name
        }
        admin {
          _id
        }
        comp {
          _id
        }
      }
      previousMaxRouteGrades {
        grade
        maxDate
      }
      previousMaxProblemGrades {
        grade
        maxDate
      }
    }
  }
  ${competitorFragment}
`

export const GET_TEAMS_INVITED_TO = gql`
  query GetTeamsInvitedTo($compId: String!, $compLinkId: String) {
    getTeamsInvitedTo(compId: $compId, compLinkId: $compLinkId) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const VALIDATE_TEAM = gql`
  mutation ValidateTeam($team: TeamInput!) {
    validateTeam(team: $team) {
      isSuccessful
      error
    }
  }
`

export const CREATE_TEAM = gql`
  mutation CreateTeam(
    $team: TeamInput!
    $category: String
    $maxProblemGrade: Float
    $maxRouteGrade: Float
    $competitorAnswers: [AnswerInput]
    $paymentIntentId: String
    $discountedPriceId: String
  ) {
    createTeam(
      team: $team
      category: $category
      maxProblemGrade: $maxProblemGrade
      maxRouteGrade: $maxRouteGrade
      competitorAnswers: $competitorAnswers
      paymentIntentId: $paymentIntentId
      discountedPriceId: $discountedPriceId
    ) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const EDIT_TEAM = gql`
  mutation EditTeam(
    $team: TeamInput!
    $category: String
    $maxProblemGrade: Float
    $maxRouteGrade: Float
    $competitorAnswers: [AnswerInput]
  ) {
    editTeam(
      team: $team
      category: $category
      maxProblemGrade: $maxProblemGrade
      maxRouteGrade: $maxRouteGrade
      competitorAnswers: $competitorAnswers
    ) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const DELETE_TEAM = gql`
  mutation DeleteTeam($teamId: String!) {
    deleteTeam(teamId: $teamId) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const ACCEPT_TEAM_INVITE = gql`
  mutation AcceptTeamInvite(
    $teamId: String!
    $compLinkId: String
    $competitor: CompetitorInput
  ) {
    acceptTeamInvite(
      teamId: $teamId
      compLinkId: $compLinkId
      competitor: $competitor
    ) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const DECLINE_TEAM_INVITE = gql`
  mutation DeclineTeamInvite($teamId: String!, $compLinkId: String) {
    declineTeamInvite(teamId: $teamId, compLinkId: $compLinkId) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const REMOVE_FROM_TEAM = gql`
  mutation RemoveFromTeam($userId: String!, $teamId: String!) {
    removeFromTeam(userId: $userId, teamId: $teamId) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const REMOVE_FROM_COMP = gql`
  mutation RemoveFromComp($competitorId: String!) {
    removeFromComp(competitorId: $competitorId) {
      ...compFragment
    }
  }
  ${compFragment}
`

export const REMOVE_FROM_COMP_BY_MANAGER = gql`
  mutation RemoveFromCompByManager($competitorId: String!) {
    removeFromCompByManager(competitorId: $competitorId) {
      ...compFragment
    }
  }
  ${compFragment}
`

export const CREATE_TEAM_FROM_FLOATERS = gql`
  mutation CreateTeamFromFloaters(
    $competitorIds: [String]!
    $compId: String!
    $adminId: String!
    $teamName: String!
  ) {
    createTeamFromFloaters(
      compId: $compId
      competitorIds: $competitorIds
      adminId: $adminId
      teamName: $teamName
    ) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const ADD_FLOATER_TO_TEAM = gql`
  mutation AddFloaterToTeam(
    $competitorId: String!
    $teamId: String!
    $compId: String!
  ) {
    addFloaterToTeam(
      competitorId: $competitorId
      teamId: $teamId
      compId: $compId
    ) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const REMOVE_COMPETITOR_FROM_TEAM = gql`
  mutation RemoveCompetitorFromTeam($competitorId: String!) {
    removeCompetitorFromTeam(competitorId: $competitorId) {
      ...competitorFragment
    }
  }
  ${competitorFragment}
`

export const GET_COMP_SET = gql`
  query GetCompSet($slug: String!) {
    compSet(slug: $slug) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const GET_COMP_SET_CLIMBS = gql`
  query GetCompSetClimbs($slug: String!) {
    compSet(slug: $slug) {
      ...basicCompSetFragment
      problems {
        ...mediumProblemFragment
      }
      routes {
        ...mediumRouteFragment
      }
    }
  }
  ${basicCompSetFragment}
  ${mediumProblemFragment}
  ${mediumRouteFragment}
`

export const CREATE_COMP_SET = gql`
  mutation CreateCompSet($compSet: CompSetInput!) {
    createCompSet(compSet: $compSet) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const EDIT_COMP_SET = gql`
  mutation EditCompSet($compSet: CompSetInput!) {
    editCompSet(compSet: $compSet) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const DELETE_COMP_SET = gql`
  mutation DeleteCompSet($compSetId: String!) {
    deleteCompSet(compSetId: $compSetId) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const ADD_CLIMB_TO_COMP_SET = gql`
  mutation AddClimbToCompSet(
    $compSetId: String!
    $climbId: String!
    $climbType: String!
  ) {
    addClimbToCompSet(
      compSetId: $compSetId
      climbId: $climbId
      climbType: $climbType
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const ADD_CLIMBS_TO_COMP_SET = gql`
  mutation AddClimbsToCompSet(
    $compSetId: String!
    $climbIds: [String]
    $climbType: String!
  ) {
    addClimbsToCompSet(
      compSetId: $compSetId
      climbIds: $climbIds
      climbType: $climbType
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const REMOVE_CLIMB_FROM_COMP_SET = gql`
  mutation RemoveClimbFromCompSet(
    $compSetId: String!
    $climbId: String!
    $climbType: String!
  ) {
    removeClimbFromCompSet(
      compSetId: $compSetId
      climbId: $climbId
      climbType: $climbType
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const REMOVE_CLIMBS_FROM_COMP_SET = gql`
  mutation RemoveClimbsFromCompSet(
    $compSetId: String!
    $climbIds: [String]
    $climbType: String!
  ) {
    removeClimbsFromCompSet(
      compSetId: $compSetId
      climbIds: $climbIds
      climbType: $climbType
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const SET_CLIMB_MAX_COMP_SCORE = gql`
  mutation SetClimbMaxCompScore(
    $compSetId: String!
    $maxCompScore: String!
    $climbId: String!
    $climbType: String!
  ) {
    setClimbMaxCompScore(
      compSetId: $compSetId
      maxCompScore: $maxCompScore
      climbId: $climbId
      climbType: $climbType
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const SET_ALL_CLIMBS_MAX_COMP_SCORE = gql`
  mutation SetAllClimbsMaxCompScore(
    $compSetId: String!
    $maxCompScore: String!
    $climbType: String!
    $gymIds: [String]
  ) {
    setAllClimbsMaxCompScore(
      compSetId: $compSetId
      maxCompScore: $maxCompScore
      climbType: $climbType
      gymIds: $gymIds
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const SET_CLIMB_NUM_HOLDS = gql`
  mutation SetClimbNumHolds(
    $compSetId: String!
    $numHolds: String!
    $climbId: String!
    $climbType: String!
  ) {
    setClimbNumHolds(
      compSetId: $compSetId
      numHolds: $numHolds
      climbId: $climbId
      climbType: $climbType
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const SET_CLIMB_COMP_NAME = gql`
  mutation SetClimbCompName(
    $compSetId: String!
    $climbId: String!
    $climbType: String!
    $climbName: String!
  ) {
    setClimbCompName(
      compSetId: $compSetId
      climbId: $climbId
      climbType: $climbType
      climbName: $climbName
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const ADD_CLIMB_AS_SPONSORED = gql`
  mutation AddClimbAsSponsored(
    $compSetId: String!
    $climbId: String!
    $climbType: String!
  ) {
    addClimbAsSponsored(
      compSetId: $compSetId
      climbId: $climbId
      climbType: $climbType
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const REMOVE_CLIMB_AS_SPONSORED = gql`
  mutation AddClimbAsSponsored(
    $compSetId: String!
    $climbId: String!
    $climbType: String!
  ) {
    addClimbAsSponsored(
      compSetId: $compSetId
      climbId: $climbId
      climbType: $climbType
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const GET_SPONSORED_WINNER = gql`
  query GetSponsoredWinner(
    $climbId: String!
    $climbType: String!
    $compSetId: String!
  ) {
    getSponsoredWinner(
      climbId: $climbId
      climbType: $climbType
      compSetId: $compSetId
    ) {
      ...competitorFragment
      competitorAnswers {
        question
        answer
      }
    }
  }
  ${competitorFragment}
`

export const GET_SPONSORED_SCANNER_ACTIVITY_WINNER = gql`
  query GetSponsoredScannerActivityWinner(
    $activityId: String!
    $compSetId: String!
  ) {
    getSponsoredScannerActivityWinner(
      activityId: $activityId
      compSetId: $compSetId
    ) {
      ...competitorFragment
      competitorAnswers {
        question
        answer
      }
    }
  }
  ${competitorFragment}
`

export const GET_CURRENT_USER_COMP_SETS = gql`
  query GetCurrentUserCompSets {
    getCurrentUserCompSets {
      ...basicCompSetFragment
      userCompetitor {
        _id
        slug
        name
        hasAskedCompNotificationPermissions
      }
    }
  }
  ${basicCompSetFragment}
`

export const GET_CURRENT_USER_JUDGE_SETS = gql`
  query GetCurrentUserJudgeSets {
    getCurrentUserJudgeSets {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`

export const GET_UPCOMING_USER_COMPS = gql`
  query GetUpcomingUserComps {
    getUpcomingUserComps {
      ...compFragment
    }
  }
  ${compFragment}
`

export const GET_COMP_SCORE = gql`
  query GetCompScore($id: String!) {
    compScore(id: $id) {
      ...basicCompScoreFragment
      compSet {
        ...basicCompSetFragment
      }
    }
  }
  ${basicCompScoreFragment}
  ${basicCompSetFragment}
`

export const GET_POSSIBLE_COMP_SCORES = gql`
  query GetPossibleCompScores(
    $id: String
    $compSetId: String
    $climbType: String
    $climbId: String
    $competitorId: String
  ) {
    possibleCompScores(
      id: $id
      compSetId: $compSetId
      climbType: $climbType
      climbId: $climbId
      competitorId: $competitorId
    ) {
      ...basicCompScoreFragment
    }
  }
  ${basicCompScoreFragment}
`

export const CREATE_COMP_SCORE = gql`
  mutation CreateCompScore(
    $compScore: CompScoreInput!
    $competitorCode: String
  ) {
    createCompScore(compScore: $compScore, competitorCode: $competitorCode) {
      ...basicCompScoreFragment
    }
  }
  ${basicCompScoreFragment}
`

export const EDIT_COMP_SCORE = gql`
  mutation EditCompScore($compScore: CompScoreInput!, $competitorCode: String) {
    editCompScore(compScore: $compScore, competitorCode: $competitorCode) {
      ...basicCompScoreFragment
    }
  }
  ${basicCompScoreFragment}
`

export const DELETE_COMP_SCORE = gql`
  mutation DeleteCompScore(
    $compScoreId: String!
    $isJudging: Boolean
    $competitorCode: String
  ) {
    deleteCompScore(
      compScoreId: $compScoreId
      isJudging: $isJudging
      competitorCode: $competitorCode
    ) {
      ...basicCompScoreFragment
    }
  }
  ${basicCompScoreFragment}
`

export const CREATE_SCANNER_COMP_SCORE = gql`
  mutation CreateScannerCompScore($scannerCompScore: ScannerCompScoreInput!) {
    createScannerCompScore(scannerCompScore: $scannerCompScore) {
      ...basicCompScoreFragment
    }
  }
  ${basicCompScoreFragment}
`

export const DELETE_SCANNER_COMP_SCORE = gql`
  mutation DeleteScannerCompScore($scannerCompScoreId: String!) {
    deleteScannerCompScore(scannerCompScoreId: $scannerCompScoreId) {
      ...basicCompScoreFragment
    }
  }
  ${basicCompScoreFragment}
`

export const TRANSFER_SCORES = gql`
  mutation TransferScores(
    $compSetId: String!
    $fromCompetitorId: String!
    $toUserId: String!
  ) {
    transferScores(
      compSetId: $compSetId
      fromCompetitorId: $fromCompetitorId
      toUserId: $toUserId
    ) {
      ...competitorFragment
    }
  }
  ${competitorFragment}
`

export const GET_COMP_NOTIFICATIONS = gql`
  query GetCompNotifications($slug: String!) {
    comp(slug: $slug) {
      _id
      endDate
      slug
      gyms {
        _id
      }
      notifications {
        ...compNotificationFragment
      }
    }
  }
  ${compNotificationFragment}
`
export const CREATE_COMP_NOTIFICATION = gql`
  mutation CreateCompNotification($compNotification: CompNotificationInput!) {
    createCompNotification(compNotification: $compNotification) {
      ...compFragment
    }
  }
  ${compFragment}
`

export const DELETE_COMP_NOTIFICATION = gql`
  mutation DeleteCompNotification($id: String!, $compId: String!) {
    deleteCompNotification(id: $id, compId: $compId) {
      ...compFragment
    }
  }
  ${compFragment}
`

export const RECALCULATE_SCORES = gql`
  mutation RecalculateScores($compSetId: String!) {
    recalculateScores(compSetId: $compSetId) {
      isSuccessful
      error
    }
  }
`

// mutation so can call imperatively
export const GET_COMP_COMPETITOR_IDS = gql`
  mutation GetCompCompetitorIds($compId: String!) {
    getCompCompetitorIds(compId: $compId) {
      _id
    }
  }
`

export const RECALCULATE_SCORE_FROM_COMPETITOR = gql`
  mutation RecalculateScoreFromCompetitor(
    $competitorId: String!
    $compSetId: String
  ) {
    recalculateScoreFromCompetitor(
      competitorId: $competitorId
      compSetId: $compSetId
    ) {
      isSuccessful
      error
    }
  }
`

export const RELOG_SCORES_FROM_SENDS = gql`
  mutation RelogScoresFromSends(
    $problemId: String
    $routeId: String
    $compId: String!
  ) {
    relogScoresFromSends(
      problemId: $problemId
      routeId: $routeId
      compId: $compId
    ) {
      ...scoreFragment
    }
  }
  ${scoreFragment}
`

// TODO: maybe deprecate this?
export const GET_COMP_SCORE_FROM_CLIMB = gql`
  query GetCompScoreFromClimb(
    $climbId: String!
    $climbType: String!
    $compId: String!
    $userId: String
  ) {
    getCompScoreFromClimb(
      climbId: $climbId
      climbType: $climbType
      compId: $compId
      userId: $userId
    ) {
      _id
      score
    }
  }
`

// should move from above to this one
export const GET_COMPSET_CLIMB_SCORE = gql`
  query GetCompSetClimbScore(
    $compSetId: String!
    $climbId: String!
    $climbType: String!
    $competitorId: String!
  ) {
    getCompSetClimbScore(
      compSetId: $compSetId
      climbId: $climbId
      climbType: $climbType
      competitorId: $competitorId
    ) {
      ...basicCompScoreFragment
    }
  }
  ${basicCompScoreFragment}
`

export const SEARCH_COMPETITORS = gql`
  query SearchCompetitors($searchStr: String!, $compId: String!) {
    searchCompetitors(searchStr: $searchStr, compId: $compId) {
      competitors {
        ...competitorFragment
      }
    }
  }
  ${competitorFragment}
`

export const SEARCH_COMP_CLIMBS = gql`
  query SearchCompClimbs($searchStr: String!, $compSetId: String!) {
    searchCompClimbs(searchStr: $searchStr, compSetId: $compSetId) {
      problems {
        ...mediumProblemFragment
      }
      routes {
        ...mediumRouteFragment
      }
    }
  }
  ${mediumProblemFragment}
  ${mediumRouteFragment}
`

export const GET_COMPETITOR = gql`
  query GetCompetitor($slug: String!) {
    competitor(slug: $slug) {
      ...competitorFragment
    }
  }
  ${competitorFragment}
`

export const GET_COMPETITORS_SCORES = gql`
  query GetCompetitorsScores($competitorId: String!, $compSetId: String) {
    getCompetitorsScores(competitorId: $competitorId, compSetId: $compSetId) {
      scores {
        ...basicCompScoreFragment
      }
    }
  }
  ${basicCompScoreFragment}
`

export const CREATE_COMPETITOR = gql`
  mutation CreateCompetitor($competitor: CompetitorInput!) {
    createCompetitor(competitor: $competitor) {
      _id
    }
  }
`

export const EDIT_COMPETITOR = gql`
  mutation EditCompetitor($competitor: CompetitorInput!) {
    editCompetitor(competitor: $competitor) {
      ...competitorFragment
    }
  }
  ${competitorFragment}
`

export const UPDATE_COMPETITOR_AS_MANAGER = gql`
  mutation UpdateCompetitorAsManager($competitor: CompetitorInput!) {
    updateCompetitorAsManager(competitor: $competitor) {
      ...competitorFragment
    }
  }
  ${competitorFragment}
`

export const UPDATE_TEAM_AS_MANAGER = gql`
  mutation UpdateTeamAsManager($team: TeamInput!) {
    updateTeamAsManager(team: $team) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const CREATE_COMP_LINK_TO_SITE = gql`
  mutation CreateCompLinkToSite($compId: String!) {
    createCompLinkToSite(compId: $compId) {
      token
    }
  }
`

export const GET_TEAM_DISCUSSION = gql`
  query Team($slug: String!) {
    team(slug: $slug) {
      ...teamFragment
      comments {
        ...feedCommentFragment
      }
    }
  }
  ${feedCommentFragment}
  ${teamFragment}
`

export const COMMENT_ON_TEAM = gql`
  mutation CommentOnTeam($slug: String!, $comment: String!) {
    commentOnTeam(slug: $slug, comment: $comment) {
      ...teamFragment
      comments {
        ...feedCommentFragment
      }
    }
  }
  ${feedCommentFragment}
  ${teamFragment}
`

export const UNCOMMENT_ON_TEAM = gql`
  mutation uncommentOnTeam($slug: String!, $commentId: String!) {
    uncommentOnTeam(slug: $slug, commentId: $commentId) {
      ...teamFragment
      comments {
        ...feedCommentFragment
      }
    }
  }
  ${feedCommentFragment}
  ${teamFragment}
`

export const GET_COMPETITORS = gql`
  query GetCompetitors($ids: [String]) {
    getCompetitors(ids: $ids) {
      ...competitorFragment
    }
  }
  ${competitorFragment}
`

export const GET_TEAMS = gql`
  query GetTeams($ids: [String]) {
    getTeams(ids: $ids) {
      ...teamFragment
    }
  }
  ${teamFragment}
`

export const GET_COMPETITORS_IN_COMP_SET = gql`
  query GetCompetitorsInCompSet($compSetId: String!, $searchStr: String) {
    getCompetitorsInCompSet(compSetId: $compSetId, searchStr: $searchStr) {
      ...competitorFragment
    }
  }
  ${competitorFragment}
`

export const GET_COMPETITOR_STAR_RATINGS = gql`
  query GetCompetitorStarRatings($compId: String!) {
    getCompetitorStarRatings(compId: $compId) {
      _id
      problems {
        _id
        name
        competitorQuality
        competitorQualities {
          _id
          stars
          email
        }
      }
      routes {
        _id
        name
        competitorQuality
        competitorQualities {
          _id
          stars
          email
        }
      }
    }
  }
`

export const SET_HAS_ASKED_COMP_NOTIFICATION_PERMISSION = gql`
  mutation SetHasAskedCompNotificationPermission($competitorId: String!) {
    setHasAskedCompNotificationPermission(competitorId: $competitorId) {
      _id
      type
      hasAskedCompNotificationPermissions
    }
  }
`

export const QUERY_COMPETITOR_FROM_CODE = gql`
  query QueryCompetitorFromCode($code: String!) {
    queryCompetitorFromCode(code: $code) {
      ...competitorFragment
    }
  }
  ${competitorFragment}
`

export const SAVE_COMP_SET_CLIMBS = gql`
  mutation SaveCompSetClimbs(
    $compSetId: String!
    $problems: [ProblemInput]
    $routes: [RouteInput]
  ) {
    saveCompSetClimbs(
      compSetId: $compSetId
      problems: $problems
      routes: $routes
    ) {
      ...basicCompSetFragment
    }
  }
  ${basicCompSetFragment}
`
